<template>
	<v-app>
		<v-main>
			<v-container class="fill-height" fluid>
				<v-row align="center" justify="center">
					<v-col cols="12" sm="8" md="4" lg="3" xl="2">
						<v-card class="elevation-12">
							<v-toolbar :color="appSetup.appColor" dense dark flat>
								<v-toolbar-title>{{ appSetup.appName }} login</v-toolbar-title>
								<div class="flex-grow-1" />
							</v-toolbar>
							<v-card-text>
								<v-form>
									<v-text-field v-model="user.username" label="username" name="login" prepend-icon="mdi-account" type="text" :autofocus="true" :rules="[rules.required]" @keyup.enter="login" />
									<v-text-field id="password" v-model="user.password" label="Password" name="password" prepend-icon="mdi-lock" :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'" :type="passwordVisible ? 'text' : 'password'" :rules="[rules.required]" @keyup.enter="login" @click:append="passwordVisible = !passwordVisible" />
								</v-form>
							</v-card-text>
							<v-card-actions>
								<div class="flex-grow-1" />
								<v-btn small :color="appSetup.appColor" @click="login">
									Login
								</v-btn>
							</v-card-actions>
							<v-card-actions v-if="error">
								<v-row>
									<v-col cols="12">
										<v-alert text class="mb-0" type="error">
											{{ errorText }}
										</v-alert>
									</v-col>
								</v-row>
							</v-card-actions>
						</v-card>

						<v-card 
							v-if="appSetup.isDevelop && testLogins"
							:color="darkTheme ? 'deep-orange darken-4' : 'deep-orange lighten-4'" class="mx-auto mt-6"
						>
							<v-card-title>BARBATRUCCO 😝 MODE</v-card-title>
							<v-card-text>
								<v-list color="transparent">
									<v-list-item-group>
										<v-list-item
											v-for="(item, i) in testLogins"
											:key="i"
										>
											<v-list-item-content>
												<v-list-item-title class="text-subtitle-1" v-text="item.d"></v-list-item-title>
												<v-list-item-subtitle class="text-caption">{{ item.u }}</v-list-item-subtitle>
											</v-list-item-content>
											<v-list-item-action>
												<v-btn small color="primary" @click="devLogin(item)">login</v-btn>
											</v-list-item-action>
										</v-list-item>
									</v-list-item-group>
								</v-list>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-container>
		</v-main>
		<loading :loading="loading" />
	</v-app>
</template>

<script>
import Loading from '@/components/loading'
export default {
	name: 'Login',
	components: { Loading },
	props: {
		dark: Boolean
	},
	data: function () {
		return {
			user: {
				username: '',
				password: ''
			},
			passwordVisible: false,
			loading: false,
			error: false,
			errorText: '',
			rules: {
				required: value => !!value || 'Required!',
			},
		}
	},
	computed: {
		appSetup() { return window.setup },
		isLoggedIn() { return this.$store.getters['isLoggedIn'] },
		testLogins() { return JSON.parse(process.env.VUE_APP_LOGIN_ACCOUNTS) },
		darkTheme() { return this.$vuetify.theme.dark },
	},
	created: function () {
		
	},
	methods: {
		devLogin(item) {
			if (this.appSetup.isDevelop) {
				this.user.username = item.u
				this.user.password = item.p
				this.passwordVisible = true // questo serve più che altro per evitare l'intervento di autocompilazione del browser
				this.login()
			}
		},
		login() {
			this.error = false
			this.loading = true
			this.$store.dispatch('login', this.user).then(() => {
				this.error = false
				this.loading = false
			}).catch((err) => {
				console.debug(err)
				this.error = true
				setTimeout(() => (this.loading = false), 200)
				if (err.message.indexOf('400') > 0 ) {
					if(err.response && err.response.data && err.response.data.Message && err.response.data.Message === 'Suspended')
						this.errorText = 'Account sospeso'
					else
						this.errorText = 'Username o password errati'
				} else 
					this.errorText = err.message
			})
		}
	}
}
</script>
